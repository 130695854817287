@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
  font-family: 'Roboto', sans-serif;
}

.App {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0px;
  /* background: linear-gradient(-10deg, #ee7752, #e73c7e, #5e8e71, #23a6d5, #23d5ab);linear-gradient(-10deg, #ee7752, #e5f1dd) */
  /*
  background: linear-gradient(-10deg, #ffffff,#9bb5b3, #e5f1dd);
  */
  background-position: 0px -30vh;
  background-repeat: no-repeat;
  background-image: radial-gradient(50% 50% at 50% 50%, rgb(179 12 12 / 10%) 0%, rgb(255 7 7 / 0%) 100%);

}

a {
  color: #EC8B5E;
}

code {
  color: #141A46;
}

.button.is-focused, .button:focus {
  border-color: black;
  color: #363636;
}

.title {
  color: inherit;
}

.Main {
  height: 100%;
}

.Navbar {
  background: transparent;
  border-bottom: 1px solid #c1c1c1;
}

.NavItem {
  margin-left: 10px;
  margin-right: 10px;
  list-style-type: none;
  font-size: 1.25rem;
  border-bottom: 1px solid transparent;
}

.NavItem.is-active-mobile {
  color: #363636;
}

.NavItem {
  color: #5f646b99;
}

@media (min-width: 350px) {
  .NavItem {
    margin: 0px;
    margin-right: 1.5rem;
  }
}

.App .carousel .slide {
  background: transparent;
}

.NavItem.is-active {
  border-bottom: 1px solid #4e4e4e;
}

.tabs .Tab.is-active a {
  color: #2e2e2e;
  border-bottom-color: #2e2e2e;
}

.handwritten {
  font-size: 1.5rem;
  font-family: 'Dancing Script', cursive;
}

.Step {
  background-color: rgba(255, 255, 255, .1); 
  color: #999;
  border-radius: 1rem;
  padding: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Step p {
  font-size: 0.9rem;
}

.Step.active {
  background-color: rgba(255, 255, 255, .25); 
  color: #2e2e2e;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

h2.subtitle {
  border-radius: 0px;
  /*box-shadow: 5px 5px 40px #f3f3f3, -5px -5px 10px #fafafa;*/
  font-weight: bold;
  margin: 1rem 0;
  margin-bottom: 0px;
}

.ActionButton {
  font-size: 1.5rem;
  font-weight: 600;
  border: 1px solid black;
  align-self: flex-start;
  margin: 1.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5%;
  cursor: pointer;
}


.PageContainer {
  height: calc(100vh - 50px);
  overflow: auto;
}

.Panel {
  border-radius: 10px;
  padding: 20px;
}

.frosted {
  background-color: rgba(255, 255, 255, .5); 
  backdrop-filter: blur(5px);
}

.button-rainbow {
  font-weight: bold;
  color: #444;
  background: #fefefe;
  border: 2px solid transparent;
  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #00d1b2 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
}

.input, .select select, .textarea {
  border-color: #bdbdbd;
}

.input:active, .input:focus, .is-active.input, .is-active.textarea, .is-focused.input, .is-focused.textarea, .select select.is-active, .select select.is-focused, .select select:active, .select select:focus, .textarea:active, .textarea:focus {
  border-color: #7a7a7a;
  box-shadow: 0 0 0 0.125em rgba(0,0,0,.25)
}


.input[readonly], .textarea[readonly] {
  box-shadow: none !important;
  background-color: #eee;
  color: #666;
  border-color: #666;
}

.input[disabled] {
 border-color: #dbdbdb ; 
}

.action-button {
  padding: 20px;
  font-weight: 700;
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, rgb(255, 0, 122) 0%, rgb(33, 114, 229) 100%), rgb(237, 238, 242);
}

.inset-text {
  text-shadow: 2px 2px 3px rgba(255,255,255,0.35);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}